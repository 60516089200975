import React from 'react'

const HeaderGeneric = (props) => (
    <header id="header">
        <h1>North Atlantic Tech</h1>
        <p>API Development - SEO - Data analytics.</p>
    </header>
)

export default HeaderGeneric
